import {Injectable} from '@angular/core';
import {AppConfigService} from '../config/app-config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() {
  }

  hasPermission(attributes): boolean {
    if (attributes) {
      const groups: string = attributes['custom:groups'];
      if (groups.includes(AppConfigService.settings.groupAdmin) ||
        groups.includes(AppConfigService.settings.groupUser)) {
        return true;
      }
    }
    return false;
  }

  hasPermissionAdmin(attributes): boolean {
    if (attributes) {
      const groups: string = attributes['custom:groups'];
      if (groups.includes(AppConfigService.settings.groupAdmin)) {
        return true;
      }
    }
    return false;
  }
}
